import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthResponseData } from '../models/authResponseData';
import { RegistrationUserModel } from '../models/registration-user.model';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { Credentials } from '../models/credentials.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userLocalStorageKey = 'authUserData';
  private tokenExpirationTimer: any;
  private tokenLifeTime = 7200000;

  authenticatedUserSubject = new BehaviorSubject<User>(null);
  public currentUserEmail: string;

  constructor(
    private http: HttpClient,
    private router: Router) { }

  public AuthorizeAppUser(credentials: Credentials): Observable<AuthResponseData> {
    return this.http.post<AuthResponseData>('api/authorization/authorize', credentials)
      .pipe(
        tap(response => {
          this.handleAuthentication(response);
        }));
  }

  public registerNewUser(userModelToRegister: RegistrationUserModel): Observable<any> {
    return this.http.post('api/authorization/register', userModelToRegister);
  }

  public getAllUsers(): Observable<RegistrationUserModel[]> {
    return this.http.get<RegistrationUserModel[]>(`api/authorization/get-users`);
  }

  public logoutUser() {
    this.authenticatedUserSubject.next(null);
    localStorage.removeItem(this.userLocalStorageKey);
    this.router.navigate(['/login']);
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  private autoLogOut(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logoutUser();
    }, expirationDuration);
  }

  public logInFromLocalStorage(): boolean {
    let parsedObject: {
      email: string,
      role: string,
      supplierGuids: string[],
      userName: string,
      _applicationToken: string,
      _linnworksToken: string,
      _expirationDate: Date;
    } = JSON.parse(localStorage.getItem(this.userLocalStorageKey));

    if (!parsedObject) {
      return false;
    }

    const localStoredUser = new User(
      parsedObject.email,
      parsedObject.role,
      parsedObject.userName,
      parsedObject._applicationToken,
      parsedObject._linnworksToken,
      new Date(parsedObject._expirationDate)
    );

    if (localStoredUser.applicationToken) {
      this.authenticatedUserSubject.next(localStoredUser);
      const expirationDuration = localStoredUser._expirationDate.getTime() - new Date().getTime();
      return true;
    } else {
      localStorage.removeItem(this.userLocalStorageKey);
      return false;
    }
  }

  public changePassword(credsToChange: Credentials): Observable<any> {
    return this.http.put('api/authorization/update-password', credsToChange);
  }

  private handleAuthentication(authResponseData: AuthResponseData) {
    const expirationdate = new Date(new Date().getTime() + authResponseData.tokenLifeTime);
    const user = new User(
      authResponseData.email,
      authResponseData.role,
      authResponseData.username,
      authResponseData.applicationAccessToken,
      authResponseData.linnworksAccessToken,

      expirationdate);
    this.authenticatedUserSubject.next(user);
    localStorage.setItem(this.userLocalStorageKey, JSON.stringify(user));
    this.currentUserEmail = authResponseData.email;
  }
}
