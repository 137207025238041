<div id="product-screen-container">
  <div class="d-flex-vert-center mg-t8 mg-b16 gap-8">
    <mat-form-field class="kr-form-field mg-r16">
      <input matInput type="text" [(ngModel)]="searchText" placeholder="Search" class="search-field"
             (ngModelChange)="onQuickFilterChanged()"/>
      <mat-icon matSuffix (click)="searchText = ''; onQuickFilterChanged()" *ngIf="searchText?.length > 0">
        clear
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="kr-form-field">
      <mat-label>Supplier:</mat-label>
      <mat-select [(value)]="selectedSupplier" (selectionChange)="selectedSupplierChange($event.value)"
      >
        <mat-option *ngFor="let supp of suppliers" [value]="supp">
          {{ supp.supplierName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="kr-form-field">
      <mat-label>Category:</mat-label>
      <mat-select [(value)]="selectedCategory" (selectionChange)="selectedCategoryChange($event.value)"
      >
        <mat-option *ngFor="let cat of categories" [value]="cat">
          {{ cat.categoryName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="kr-form-field mg-r16">
      <mat-label>Location:</mat-label>
      <mat-select [(value)]="selectedLocation" (selectionChange)="selectedLocationChange($event.value)"
      >
        <mat-option *ngFor="let location of locations" [value]="location">
          {{ location.locationName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="kr-form-field" *ngIf="columnDefs?.length">
      <mat-label>Column:</mat-label>
      <input type="text"
             placeholder="Pick one"
             aria-label="Column"
             matInput
             [formControl]="columnFilter"
             [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="onColumnSelectionChange($event)">
        <mat-option *ngFor="let c of filteredOptions | async" [value]="c.headerName">
          {{c.headerName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="d-flex-wrap gap-4 mg-b8" *ngIf="views?.length">
    <div class="view-button d-flex-vert-center" *ngFor="let view of views"
            [ngClass]="{
             'view-button-active': currentView?.id === view.id,
              'view-button-default': view.isDefault
            }">
      <span (click)="filterTableByView(view)">{{ view.title }}</span>
      <mat-icon [matMenuTriggerFor]="menu" (click)="manipulateView($event, view)">arrow_drop_down</mat-icon>
      <mat-menu #menu="matMenu">
        <span mat-menu-item (click)="editView($event)">Edit View</span>
        <span mat-menu-item (click)="deleteView($event)">Delete View</span>
        <span mat-menu-item (click)="setViewAsDefault($event)" *ngIf="!view?.isDefault">Set as default</span>
      </mat-menu>
    </div>
    <div id="add-new-view" class="view-button">
      <mat-icon (click)="addNewView()">add</mat-icon>
    </div>
  </div>

  <ag-grid-angular id="product-screen-table-container"
                   class="ag-theme-balham"
                   (gridReady)="onGridReady($event)"
                   [columnDefs]="columnDefs"
                   rowSelection="multiple"
                   [gridOptions]="gridOptions"
                   (cellClicked)="onCellClicked($event)"
                   (cellDoubleClicked)="onCellDoubleClicked($event)"
                   (cellValueChanged)="onCellValueChanged($event)"
                   [stopEditingWhenGridLosesFocus]="true"
                   (bodyScroll)="onGridScroll($event)"
                   (sortChanged)="onSortChanged($event)"
                   [animateRows]="true"
                   [frameworkComponents]="frameworkComponents"
                   tooltipShowDelay="0"
                   applyColumnDefOrder="true"
                   [enableCellChangeFlash]="true">
    <!-- multiSortKey="ctrl" -->
  </ag-grid-angular>

  <div class="d-flex-vert-center d-flex-sp-btw mg-t8 mg-b8">
    <div class="d-flex-vert-center gap-8">
      <button mat-stroked-button (click)="createNewItem()">Create New Item</button>

      <button mat-stroked-button (click)="configureSupplierState()" *ngIf="!loading">
        Suppliers configuration
      </button>

      <button mat-stroked-button *ngIf="authService.currentUserEmail === 'kabir@doodle-products.com'" (click)="exportExcel()">Export as
        XLSX
      </button>

      <button mat-stroked-button (click)="openInventoryImagesDialogComponent()" *ngIf="selectedItem">
        Inventory Images
      </button>

      <button mat-stroked-button (click)="openSpecSheetDialogComponent()" *ngIf="selectedItem">
        Spec Sheet
      </button>

      <button mat-stroked-button (click)="openInstructionsDialogComponent()" *ngIf="selectedItem">
        Instructions
      </button>

      <button mat-stroked-button (click)="duplicateInventoryItem()" *ngIf="selectedItem">
        Duplicate
      </button>

      <button mat-stroked-button (click)="setItemAsDiscontinued()" *ngIf="selectedItem">
        Set as discontinued
      </button>

      <button mat-stroked-button (click)="resetStockValue()" *ngIf="selectedItem && selectedItem.StockValueIsOverridden">
        Reset stock value
      </button>

      <button mat-stroked-button (click)="deleteItem()" *ngIf="selectedItem">Delete</button>
    </div>
    <div class="total">
      <span *ngIf="pagedList">Total items: {{ pagedList.totalCount }}</span>
    </div>
  </div>

  <div class="d-flex-vert-center d-flex-sp-btw">
    <div class="d-flex-vert-center gap-8">
      <button mat-stroked-button (click)="openPriceChanger()">
        Price Change
      </button>

      <button mat-stroked-button (click)="openNewPriceRuleDialog()">
        Add price rule
      </button>

      <!-- <button mat-stroked-button (click)="openPriceRules()">
        Price Rules
      </button> -->

      <!-- <button mat-stroked-button (click)="exportToExcel()">
        Export To Excel
      </button> -->

      <button mat-stroked-button (click)="copyPriceToAllChannels()" *ngIf="selectedItem || lastSelectedPriceItem">Copy Price to All
        channels
      </button>

      <button mat-stroked-button (click)="priceUp()" *ngIf="selectedItem">Price Up</button>

      <input class="priceUpInput" type="number" step="0.01" [(ngModel)]="priceUpAmount" *ngIf="selectedItem">
    </div>

    <mat-slide-toggle [(ngModel)]="showCombos" (change)="showCombosToggleChange($event)" class="total">
      Show combo items for direct price change
    </mat-slide-toggle>

  </div>
</div>
<input name="file" id="file" (change)="uploadFiles($event)" type="file" multiple accept="image/*" #inputFile hidden />

