import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription, take, tap } from 'rxjs';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  tokenFromUrl: string;
  session: any;
  queryParamsSubscription: Subscription;

  autorized: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
  ) {

    setTimeout(() => {

      this.route.queryParams.pipe(tap(params => {
        const itemGuidii = params['itemGuidii'];
        const itemGuid = params['itemGuid'];
        const itemGuidPackaging = params['itemGuidPackaging'];
        const itemGuidInstruction = params['itemGuidInstruction'];
        const purchaseId = params['purchaseId'];
        const purchaseNumber = params['purchaseNumber'];

        if (itemGuid) {
          this.autorized = true;
          sessionStorage.setItem('lwkr-stockitemid-ss', itemGuid);
          router.navigate(['/spec-sheet']);
        }

        if (itemGuidii) {
          this.autorized = true;
          sessionStorage.setItem('lwkr-stockitemid-ii', itemGuidii);
          router.navigate(['/inventory-images']);
        }

        if (itemGuidPackaging) {
          this.autorized = true;
          sessionStorage.setItem('lwkr-stockitemid-packaging', itemGuidPackaging);
          router.navigate(['/inventory-packaging']);
        }

        if (itemGuidInstruction) {
          this.autorized = true;
          sessionStorage.setItem('lwkr-stockitemid-instruction', itemGuidInstruction);
          router.navigate(['/inventory-instructions']);
        }

        if (purchaseId) {
          this.autorized = true;
          sessionStorage.setItem('qc-purchaseId', purchaseId);
          sessionStorage.setItem('qc-purchaseNumber', purchaseNumber);
          router.navigate(['/qc-photos']);
        }

        if (!itemGuid && !itemGuidii && !itemGuidPackaging && !itemGuidInstruction && !purchaseId) {
          this.autorized = this.authService.logInFromLocalStorage();
          if (!this.autorized) {
            void this.router.navigate(["/login"]);
          } else {
            void this.router.navigate(["/home"]);
          }
        }
      }),
        take(1)).subscribe();
    }, 0);
  }
}
