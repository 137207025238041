import { SuppliersStateConfigurationComponent } from "./suppliers-state-configuration/suppliers-state-configuration.component";
import { ComponentType } from "@angular/cdk/portal";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver-es";
import { mergeMap, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
  tap,
} from "rxjs/operators";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { Category } from "src/app/shared/models/category";
import { EditInventoryItemTitle } from "src/app/shared/models/newInventoryItem";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { IntakeDialogComponent } from "../procurement-new/intake-dialog/intake-dialog.component";
import {
  Column,
  Filter,
  PagedList,
  PropertyType,
  SortDirection,
  StockValueModel,
  SupplierProductScreen,
  UpdateStockItemPrice,
  View,
} from '../../shared/models/product-screen.models';
import { Location, POL } from '../../shared/models/purchase.models';
import { AddNewItemComponent } from "./add-new-item/add-new-item.component";
import { CreateViewDialogComponent } from "./create-view-dialog/create-view-dialog.component";
import { FullSizeImageTooltipComponent } from "./full-size-image-tooltip/full-size-image-tooltip.component";
import { ImageRendererComponent } from "./image-renderer/image-renderer.component";
import { InstructionsDialogComponent } from "./instructions-dialog/instructions-dialog.component";
import { InventoryImagesDialogComponent } from "./inventory-images-dialog/inventory-images-dialog.component";
import { SpecSheetDialogComponent } from "./spec-sheet-dialog/spec-sheet-dialog.component";
import { PriceChangerComponent } from "./price-changer/price-changer.component";
import { PriceChangeRulesComponent } from "./price-change-rules/price-change-rules.component";
import { AllPriceRulesComponent } from "./all-price-rules/all-price-rules.component";
import { UserService } from "src/app/shared/services/user.service";
import { purchaseOrderNumberComparator, SKUComparator } from "src/app/shared/utils/functions";
import { UntypedFormControl } from "@angular/forms";
import { AuthService } from "src/app/core/services/auth.service";
import { StockItemSupplierInfo } from "src/app/shared/models/StockItemSupplierInfo";
import { ExcelService } from "src/app/shared/services/excel.service";
import { PurchaseService } from '../../shared/services/purchase.service';
import { G } from "@angular/cdk/keycodes";

@Component({
  selector: "app-product-screen",
  templateUrl: "./product-screen.component.html",
  styleUrls: ["./product-screen.component.scss"],
})
export class ProductScreenComponent implements OnInit, OnDestroy {
  //#region Properties

  //<!------------------AG-GRID-----------------------!>
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions;
  quickFilter: string;
  columnDefsBase: any = [
    {
      field: "SKU",
      width: 120,
      valueGetter: (params) => {
        let suffix: string = "";
        if (
          params.data.IsCompositParent &&
          params.data.Composites &&
          params.data.Composites.length > 0 &&
          params.data.Composites[0] !== ""
        ) {
          //parent
          suffix = params.data.isToggled ? "- " : "+ ";
        } else {
          suffix = "";
        }
        return suffix + params.data.SKU;
      },
      editable: false,
      pinned: "left",
      sortable: true,
      comparator: purchaseOrderNumberComparator,
      getQuickFilterText: function (params) {
        return params.value;
      }
    },
    {
      field: "Title",
      editable: true,
      pinned: "left",
      getQuickFilterText: function (params) {
        return params.value;
      }
    },
    {
      headerName: "Primary Image",
      field: "PrimaryImage",
      editable: false,
      pinned: "left",
      cellRenderer: "imageRenderer",
      tooltipField: "PrimaryImage",
      tooltipComponent: "fullSizeImageTooltip",
    },
  ];
  frameworkComponents = {
    imageRenderer: ImageRendererComponent,
    fullSizeImageTooltip: FullSizeImageTooltipComponent,
  };
  columnDefs;

  columnDefsForColumnFilter: any[];
  filteredOptions: Observable<any[]>;

  //<!------------------!AG-GRID-----------------------!>
  columnFilter = new UntypedFormControl();
  viewHeight: string;
  sortColumn: string;
  sortDirection: string;
  selectedItem;
  urlDoubleClick: boolean = false;
  columns: Column[];
  filters: Filter[] = [];
  views: View[] = [];
  currentView: View;
  viewToManipulate: View = null;
  viewIdToDelete: number = -1;
  pagedList: PagedList = {
    pageNumber: 1,
    totalCount: 0,
    itemsPerPage: 5000,
    items: [],
  };
  selectedSupplier: SupplierProductScreen;
  suppliersBase: SupplierProductScreen[];
  suppliers: SupplierProductScreen[];
  ports: POL[];
  categories: Category[];
  selectedCategory: Category;
  locations: Location[];
  selectedLocation: Location;
  comboItems: string[] = [];
  modelChanged: Subject<string> = new Subject<string>();
  // @ViewChild("viewManipulationRef") viewManipulationRef: ElementRef;
  // @ViewChild("viewContainerRef") viewContainerRef: ElementRef;
  destroyedComponentSubj = new Subject<void>();
  //#endregion

  lastChangedPrice: any;
  lastSelectedPriceItem: any;
  priceUpAmount: number = 1.00;
  showCombos: boolean = false;

  searchText: string = '';

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    private snackBarService: SnackBarService,
    private inventoryService: InventoryService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    public authService: AuthService,
    private excelService: ExcelService,
    private purchaseService: PurchaseService,
  ) { }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.columnDefsForColumnFilter?.filter(option => option.headerName.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
    this.getGridOptions();
    this.getColumns();
    this.getViews();
    this.getSuppliers();
    this.getCategories();
    this.getStockLocations();
    this.getSubscriptions();

    this.filteredOptions = this.columnFilter.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  // ngAfterViewChecked() {
  //   this.onResize(this.viewContainerRef.nativeElement.offsetWidth);
  //   this.cdr.detectChanges();
  // }

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  //#region Subscriptions
  getSubscriptions() {
    // this.renderer.listen("window", "click", (e: Event) => {
    //   if (
    //     this.viewManipulationRef &&
    //     e.target !== this.viewManipulationRef.nativeElement
    //   ) {
    //     this.viewToManipulate = null;
    //   }
    // });

    this.modelChanged
      .pipe(
        debounceTime(500), // wait 300ms after the last event before emitting last event
        distinctUntilChanged() // only emit if value is different from previous value
      )
      .subscribe((text: string) => {
        setTimeout(() => {
          this.selectedItem = null;
          this.gridApi.setRowData([]);
          this.pagedList.totalCount = 0;
          this.loadProductItems(
            this.currentView,
            1,
            this.pagedList.itemsPerPage,
            null,
            null,
            this.selectedSupplier?.pkSupplierID,
            this.selectedCategory?.categoryId,
            this.selectedLocation?.stockLocationId,
            text,
            true,
            this.showCombos
          );
          this.pagedList.pageNumber = 1;
        }, 0);
      });
  }


  //#endregion

  //#region Data Loading

  getColumns() {
    this.inventoryService
      .getProductScreenColumns()
      .pipe(
        takeUntil(this.destroyedComponentSubj),
        map((columns: Column[]) => {
          columns = columns.filter(
            (x) =>
              !["IsCompositParent", "SKU", "Title", "PrimaryImage"].includes(
                x.propertyName
              )
          );

          return columns;
        })
      )
      .subscribe((columns: Column[]) => {
        this.columns = columns;

        this.columns.forEach((column: Column) => {
          let itemColumnDef: any = {
            headerName: column.name,
            field: column.propertyName,
            propertyType: column.propertyType,
            sortable: true,
            getQuickFilterText: () => '',
            // comparator: () => 0,
            comparator: ["AmazonPageViews", "AmazonRatings", "AmazonStarRating", "AmazonRank"].includes(column.propertyName)
              ? SKUComparator
              : (valueA, valueB, nodeA, nodeB, isInverted) => {
                if (isNaN(valueA)) return -1;
                if (valueA == valueB) return 0;
                return (Number(valueA) > Number(valueB)) ? 1 : -1;
              },
            editable:
              column.propertyType === PropertyType.ExtendedProperty ||
              [
                "FactoryPrice",
                "SupplierName",
                "CategoryName",
                "StockValue",
                "WooCommerceSalePrice"
              ].includes(column.propertyName) || column.propertyName.toLowerCase().includes("channelprice"),
          };
          if (
            [
              "LandedCostStockValue",
              "StockLevel",
              "StockValue",
              "FactoryPrice",
              "WooCommerceSalePrice"
            ].includes(column.propertyName)
          ) {
            itemColumnDef.valueFormatter = digitFormatter;
          }
          if (column.propertyName === "SupplierName") {
            itemColumnDef.cellEditor = "agSelectCellEditor";

            itemColumnDef.cellEditorParams = () => {
              return {
                values: this.suppliersBase
                  .map((x) => x.supplierName)
                  .filter((x) => x != "All"),
              };
            };
          } else if (column.propertyName === "CategoryName") {
            itemColumnDef.cellEditor = "agSelectCellEditor";
            itemColumnDef.width = 200;

            itemColumnDef.cellEditorParams = () => {
              return {
                values: this.categories
                  .map((x) => x.categoryName)
                  .filter((x) => x != "All"),
              };
            };
          } else if (column.propertyName === "StockValue") {
            itemColumnDef.cellStyle = function (params) {
              if (!params.colDef.field.includes("StockValue")) {
                return;
              }

              if (params.data.StockValueIsOverridden) {
                return { backgroundColor: "#F1948A" };
              } else {
                return { backgroundColor: "" };
              }
            };
          }

          this.columnDefsBase.push(itemColumnDef);
        });

        this.columnDefsBase
          .filter((x) =>
            this.inventoryService.notSortablePropsProductScreen.includes(
              x.field
            )
          )
          .forEach((element) => {
            element.sortable = true;
            element.getQuickFilterText = () => '';
            // element.comparator = this.SKUComparator;
          });
        this.columnDefs = this.columnDefsBase;


      });
  }

  getViews() {
    this.inventoryService
      .getAllViews()
      .pipe(
        takeUntil(this.destroyedComponentSubj),
        map((views: View[]) => {
          const view = views.filter((x) => x.isDefault)[0];
          if (view) {
            views = views.filter((item: View) => item.id !== view.id);
            views.unshift(view);
          }
          return views;
        })
      )
      .subscribe((views: View[]) => {
        this.views = views;
        this.loadInitialDataForDefaultView();
      });
  }

  getSuppliers() {
    this.inventoryService
        .getSuppliersForProductScreen()
        .pipe(
            mergeMap((result) => {
              this.suppliersBase = result;
              this.suppliers = this.suppliersBase.filter((x) => x.active);

              this.suppliers.unshift({
                supplierName: 'All',
                pkSupplierID: '',
                active: true,
                portId: null,
              });
              return this.purchaseService.getPOLs();
            }),
            tap((ports) => {
              this.ports = ports;
            }),
            takeUntil(this.destroyedComponentSubj)
        )
        .subscribe();
  }

  getCategories() {
    this.inventoryService
      .getCategories()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.categories = result;
          this.categories.unshift({
            categoryName: "All",
            categoryId: null,
          });
        },
        (error) => {
          console.error(error);
          this.snackBarService.error("Can not load categories");
        }
      );
  }

  getStockLocations() {
    this.inventoryService
      .getStockLocations()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result: Location[]) => {
          this.locations = result;
          this.locations.unshift({ locationName: "All", stockLocationId: "" });
        },
        (error) => {
          console.error(error);
          this.snackBarService.error("Can not load locations");
        }
      );
  }


  loading: boolean = false;
  loadProductItems(
    view: View,
    pageNumber: number,
    pageSize: number,
    sortColumn?: string,
    sortDirection?: string,
    supplierId?: string,
    categoryId?: string,
    locationId?: string,
    search?: string,
    includeFiltering?: boolean,
    showCombos?: boolean
  ): void {
    this.loading = true;
    this.inventoryService
      .getProductScreenItems(
        view.id,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        supplierId,
        categoryId,
        locationId,
        search,
        includeFiltering,
        showCombos
      )
      .pipe(
        takeUntil(this.destroyedComponentSubj),
        map((data) => {
          if (!data) {
            return;
          }
          data.items.forEach((item) => {
            item.isToggled = false;
            item.isChild = false;
          });
          return data;
        })
      )
      .subscribe((result: PagedList) => {
        this.columnDefs = this.columnDefsBase.filter((x) =>
          ["composite", "sku", "title", "primaryimage"].includes(
            x.field.toLowerCase()
          )
        );
        this.currentView.columns
          .filter(
            (x) =>
              !["composite", "sku", "title", "primaryimage"].includes(
                x.propertyName.toLowerCase()
              )
          )
          .forEach((column: Column) => {
            let columnDefItem = {
              ...this.columnDefsBase.filter(
                (x) => x.field === column.propertyName
              )[0],
            };
            if (column.sortDirection !== SortDirection.None) {
              columnDefItem.headerClass =
                SortDirection[column.sortDirection].toLowerCase();
            }
            this.columnDefs.push(columnDefItem);
          });

        this.loading = false;
        if (!result) {
          this.pagedList.totalCount = 0;
          return;
        }
        const itemsWithoutComposites =
          this.removeCompositesFromItemsThatAreCombo(result.items);
        this.pagedList.totalCount =
          result.totalCount -
          (result.items.length - itemsWithoutComposites.length);
        result.items = itemsWithoutComposites;
        this.gridApi.applyTransaction({ add: result.items });
      });
  }

  //#endregion

  //#region Ag-Grid
  getGridOptions() {
    this.gridOptions = {
      rowClassRules: {
        composite: function (params) {
          return params.data.isChild;
        },
        nestedComposite: function (params) {
          return params.data.isNestedChild;
        },
      },
      defaultColDef: {
        resizable: true,
        // sortable: true,
        width: 100,
        wrapText: true,
        editable: true,
        tooltipComponent: "GridTooltipComponent",
        headerComponentParams: {
          template: getHeaderTemplate(),
        },
        comparator: SKUComparator
      },
      rowHeight: 56,
      headerHeight: 50,
    };
  }

  onGridScroll(event) {
    // //1 row = 40 offset
    // const amountOfPages: number = Math.ceil(
    //   this.pagedList.totalCount / this.pagedList.itemsPerPage
    // );

    // if (this.pagedList.pageNumber + 1 >= amountOfPages) {
    //   return;
    // }

    // if (event.top / 60 > this.pagedList.pageNumber * amountOfPages) {
    //   this.pagedList.pageNumber++;
    //   if (this.pagedList.pageNumber === 1) {
    //     this.pagedList.pageNumber++;
    //   }
    //   this.loadProductItems(
    //     this.currentView,
    //     this.pagedList.pageNumber,
    //     this.pagedList.itemsPerPage,
    //     this.sortColumn,com
    //     this.sortDirection,
    //     this.selectedSupplier?.pkSupplierID,
    //     this.selectedCategory?.categoryId,
    //     this.selectedLocation?.stockLocationId,
    //     this.quickFilter,
    //     true,
    //     this.showCombos
    //   );
    // }
  }

  onSortChanged(event) {
    return;
    const colState = this.gridColumnApi
      .getColumnState()
      .find((x) => x.sort !== null);
    const sortModel = { colId: colState?.colId, sort: colState?.sort };
    if (!sortModel || sortModel.colId.toLowerCase() === "composite") {
      return;
    }

    const columns = this.gridColumnApi.getAllDisplayedColumns();
    columns.forEach((column: any) => {
      column.colDef.headerClass = null;
    });
    this.gridApi.refreshHeader();

    const sortColumn = sortModel.colId;
    let sortDirection = null;

    if (sortModel.sort === 'asc') {
      sortDirection = 'desc';
    } else if (sortModel.sort === 'desc') {
      sortDirection = 'asc';
    }
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;

    if (this.inventoryService.notSortablePropsProductScreen.indexOf(sortColumn) !== -1) return;
    this.inventoryService
      .getProductScreenItems(
        this.currentView.id,
        1,
        this.pagedList.itemsPerPage,
        sortColumn,
        sortDirection,
        this.selectedSupplier?.pkSupplierID,
        this.selectedCategory?.categoryId,
        this.selectedLocation?.stockLocationId,
        this.quickFilter,
        true,
        this.showCombos
      )
      .subscribe((response: PagedList) => {
        this.pagedList.pageNumber = 0;
        this.gridApi.setRowData([]);
        response.items = this.removeCompositesFromItemsThatAreCombo(
          response.items
        );
        this.pagedList.totalCount = response.totalCount;
        this.gridApi.applyTransaction({ add: response.items });
      });
  }

  compositeLoading: boolean = false;
  onCellClicked(event) {
    const selectedRowIndex = event.rowIndex;

    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
      // this.lastChangedPrice = null;
    }
    if (event.column.colDef.field.toLowerCase() === "primaryimage") {
      return;
    }

    if (event.column.colDef.field.toLowerCase() === "sku") {
      if (
        event.value.trimStart()[0] !== "+" &&
        event.value.trimStart()[0] !== "-"
      ) {
        return;
      }

      if (this.compositeLoading) {
        return;
      }
      event.data.isToggled = !event.data.isToggled;

      if (!event.data.isToggled) {
        //hide
        if (!this.selectedItem.compositeItems) {
          return;
        }

        this.selectedItem.compositeItems.forEach((item) => {
          this.pagedList.totalCount--;
          if (item.compositeItems && item.isToggled) {
            this.pagedList.totalCount -= item.Composites.length;
          }
          this.gridApi.applyTransaction({
            remove: item.compositeItems,
          });
        });

        this.gridApi.applyTransaction({
          remove: this.selectedItem.compositeItems,
        });
        this.selectedItem.compositeItems = [];
      } else {
        this.compositeLoading = true;
        this.inventoryService
          .getProductScreenItemsComposites(
            this.currentView.id,
            1,
            this.pagedList.itemsPerPage,
            this.selectedItem.Composites
          )
          .pipe(
            map((response: PagedList) => {
              if (!response) {
                return;
              }
              response.items.forEach((element) => {
                element.isChild = true;
              });

              if (this.selectedItem.isNestedParent) {
                response.items.forEach((element) => {
                  element.isNestedChild = true;
                });
              }
              response.items = response.items.sort((a, b) =>
                a.SKU.includes("box") && !b.SKU.includes("box") && a.SKU > b.SKU
                  ? -1
                  : 1
              );
              return response;
            })
          )
          .subscribe((response: PagedList) => {
            if (!response) {
              return;
            }
            this.selectedItem.compositeItems = response.items;
            this.selectedItem.compositeItems.forEach((element) => {
              if (
                element.Composites &&
                element.Composites.length > 0 &&
                element.Composites[0] != ""
              ) {
                element.isNestedParent = true;
              }
            });
            this.gridApi.applyTransaction({
              add: this.selectedItem.compositeItems,
              addIndex: selectedRowIndex + 1,
            });
            this.pagedList.totalCount +=
              this.selectedItem.compositeItems.length;
            this.compositeLoading = false;
          });
      }
    } else if (event.column.colDef.field.toLowerCase() === "due") {
      this.inventoryService
        .GetPurchaseIdsByStockItemId(this.selectedItem.StockItemId)
        .subscribe((response) => {
          if (response.purchaseIds) {
            this.dialog.open(IntakeDialogComponent, {
              data: {
                stockItem: {
                  stockItemId: this.selectedItem.StockItemId,
                  purchaseOrders: [response.purchaseIds],
                },
                weekNumber: 0,
              },
            });
          } else {
            this.snackBarService.neutral(
              `There are no purchases for ${this.selectedItem.SKU}`,
              "No content"
            );
          }
        });
    } else {
      setTimeout(() => {
        if (this.urlDoubleClick) {
          return;
        } else {
          if (
            event.value &&
            event.value.toString().toLowerCase().trimStart().startsWith("http")
          ) {
            window.open(event.value, "_blank");
          }
        }
      }, 300);
    }
  }

  onCellDoubleClicked(event) {
    this.urlDoubleClick = true;
    setTimeout(() => {
      this.urlDoubleClick = false;
    }, 500);
    return;
  }

  onCellValueChanged(event) {
    if (event.colDef.field === "SupplierName") {
      const supplier: SupplierProductScreen = this.suppliersBase.find(
        (supplier: SupplierProductScreen) =>
          supplier.supplierName === event.value
      );

      this.inventoryService
        .AddSupplierToInventoryItem(
          event.data.StockItemId,
          supplier.pkSupplierID
        )
        .subscribe(
          () => {
            this.inventoryService
              .setDefaultSupplierForInventoryItem(
                event.data.StockItemId,
                supplier.pkSupplierID
              )
              .subscribe((x) => {
                this.inventoryService
                  .getProductScreenItems(
                    this.currentView.id,
                    1,
                    this.pagedList.itemsPerPage,
                    null,
                    null,
                    null,
                    null,
                    null,
                    event.data.SKU,
                    true,
                    this.showCombos
                  )
                  .pipe(
                    map((data) => {
                      if (!data) {
                        return;
                      }
                      data.items.forEach((item) => {
                        item.isToggled = false;
                        item.isChild = false;
                      });
                      return data;
                    })
                  )
                  .subscribe((result: PagedList) => {
                    if (result) {
                      let selectedNode = this.gridApi.getSelectedNodes()[0];
                      selectedNode.data = { ...result.items[0] };
                      this.gridApi.applyTransaction({
                        update: [selectedNode.data],
                      });
                      this.gridApi.flashCells({
                        rowNodes: [selectedNode],
                      });
                    }
                  });

                const selectedRowIndex = event.rowIndex;
                let items = this.getRowDataAfterFilter();
                const parent = items.find(x => x.Composites.some(i => i === event.data.StockItemId.toUpperCase()));

                if (parent) {
                  this.inventoryService
                  .getProductScreenItemsComposites(
                    this.currentView.id,
                    1,
                    this.pagedList.itemsPerPage,
                    parent.Composites
                  )
                  .subscribe((response: PagedList) => {
                    if (!response) {
                      return;
                    }
                    // this.selectedItem.compositeItems = response.items;
                    // this.selectedItem.compositeItems.forEach((element) => {
                    //   if (
                    //     element.Composites &&
                    //     element.Composites.length > 0 &&
                    //     element.Composites[0] != ""
                    //   ) {
                    //     element.isNestedParent = true;
                    //   }
                    // });
                    let itemsToUpdate = [];
                    this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
                      const itemToUpdate = response.items.find(i => i.StockItemId === rowNode.data.StockItemId);
                      if (itemToUpdate) {
                        let data = rowNode.data;
                        rowNode.data = itemToUpdate;
                        itemsToUpdate.push(data);
                        this.gridApi.redrawRows();
                        this.gridApi.applyTransaction({
                          update: itemsToUpdate,
                        });
                      }

                    });

                    // this.pagedList.totalCount +=
                    //   this.selectedItem.compositeItems.length;
                    // this.compositeLoading = false;
                  });
                }

              });
          },
          (error) => {
            this.snackBarService.error(
              "Something went wrong, open console for more info"
            );
            console.error(error);
          }
        );
      return;
    } else if (event.colDef.field === "CategoryName") {
      const category = this.categories.find(
        (x) => x.categoryName === event.value
      );
      this.inventoryService
        .UpdateInventoryCategory(event.data.StockItemId, category)
        .subscribe((response) => {
          if (response.status === 204) {
            this.snackBarService.success(
              `Category was changed to ${category.categoryName}`
            );
          }
        });
    } else if (event.colDef.field.toLowerCase().includes("channelprice")) {
      if (isNaN(event.value)) {
        this.snackBarService.warning("Please enter valid price");
        return;
      }
      const columnName = event.colDef.field.toLowerCase();
      let channelId = 0;
      if (columnName === "amazonchannelprice") channelId = 1;
      if (columnName === "ebaychannelprice") channelId = 2;
      if (columnName === "hometreatschannelprice") channelId = 3;
      if (columnName === "bedsalechannelprice") channelId = 4;
      if (columnName === "amazonfbachannelprice") channelId = 5;
      if (columnName === "onbuychannelprice") channelId = 6;
      if (columnName === "miraklmpchannelprice") channelId = 7;
      if (columnName === "rangechannelprice") channelId = 8;
      if (columnName === "debenhamschannelprice") channelId = 9;
      if (columnName === "tescochannelprice") channelId = 10;

      const updateChannelPriceModel: UpdateStockItemPrice = {
        stockItemId: event.data.StockItemId,
        channelId: channelId,
        price: parseFloat(event.value),
        oldPrice: parseFloat(event.oldValue) ?? 0.0,
        channel: columnName.replace("channelprice", ""),
      };

      this.inventoryService.updateChannelPrice(updateChannelPriceModel).subscribe(result => {
        this.lastChangedPrice = {
          stockItemId: event.data.StockItemId,
          price: event.value
        };
        this.lastSelectedPriceItem = this.gridApi.getSelectedRows()[0];
        // this.filterTableByView(this.currentView);

        //
        this.inventoryService
          .getProductScreenItems(
            this.currentView.id,
            this.pagedList.pageNumber + 1,
            this.pagedList.itemsPerPage,
            this.sortColumn,
            this.sortDirection,
            this.selectedSupplier?.pkSupplierID ?? null,
            this.selectedCategory?.categoryId ?? null,
            this.selectedLocation?.stockLocationId,
            this.quickFilter,
            true,
            this.showCombos
          )
          .pipe(
            takeUntil(this.destroyedComponentSubj),
            map((data: PagedList) => {
              if (!data) {
                return;
              }
              return data;
            })
          )
          .subscribe((result: PagedList) => {
            this.loading = false;
            if (!result) {
              this.pagedList.totalCount = 0;
              return;
            }
            let itemsToUpdate = [];
            setTimeout(() => {
              this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
                const updatedItem = result.items.find(i => i.StockItemId === rowNode.data.StockItemId);
                let data = rowNode.data;
                data.AmazonChannelPrice = updatedItem.AmazonChannelPrice;
                data.AmazonFBAChannelPrice = updatedItem.AmazonFBAChannelPrice;
                data.EbayChannelPrice = updatedItem.EbayChannelPrice;
                data.OnBuyChannelPrice = updatedItem.OnBuyChannelPrice;
                data.HometreatsChannelPrice = updatedItem.HometreatsChannelPrice;
                data.MiraklMPChannelPrice = updatedItem.MiraklMPChannelPrice;
                data.RangeChannelPrice = updatedItem.RangeChannelPrice;
                data.DebenhamsChannelPrice = updatedItem.DebenhamsChannelPrice;
                data.TescoChannelPrice = updatedItem.TescoChannelPrice;
                itemsToUpdate.push(data);
              });
              this.gridApi.applyTransaction({ update: itemsToUpdate });
            }, 500);
          });
        //

      });

    } else if (event.colDef.field === "FactoryPrice") {
      if (isNaN(event.value) || +event.value < 0) {
        this.snackBarService.warning(
          "Factory Price could not contain non-numeric characters or be lower than 0"
        );
        event.data.FactoryPrice = event.oldValue;
        this.gridApi.refreshCells();
        return;
      }
      this.inventoryService
        .changeFactoryPrice(event.data.StockItemId, +event.value)
        .subscribe(() => {
          this.snackBarService.success("Successfully updated");
        });
    } else if (event.colDef.field === "StockValue") {
      if (isNaN(event.value) || +event.value < 0) {
        this.snackBarService.warning(
          "StockValue could not contain non-numeric characters or be lower than 0"
        );
        event.data.StockValue = event.oldValue;
        this.gridApi.refreshCells();
        return;
      }
      const model: StockValueModel = {
        stockItemId: event.data.StockItemId,
        stockValue: +event.value,
      };
      this.inventoryService.ChangeStockValue(model).subscribe(() => {
        this.snackBarService.success("Successfully updated");
        event.data.StockValueIsOverridden = true;
        this.gridApi.redrawRows();
      });
    } else if (event.colDef.propertyType === PropertyType.ExtendedProperty) {

      if (event.colDef.field === "Cbm" || event.colDef.field === "CtnQty") {
        this.inventoryService.updateSupplierData(new StockItemSupplierInfo({
          stockItemId: event.data.StockItemId,
          supplierId: event.data.fkSupplierId,
          cbm: event.data.Cbm,
          cartonQuantity: event.data.CtnQty,
          purchasePriceUSD: event.data.FactoryPrice
        })).pipe(
          tap(() => {

          }),
          takeUntil(this.destroyedComponentSubj)
        ).subscribe();
      } else {
        const stockItemExtendedProperty = {
          fkStockItemId: event.data.StockItemId,
          properyName: event.colDef.headerName,
          propertyValue: event.value,
        };

        this.inventoryService
          .updatetStockItemExtendedProperty(stockItemExtendedProperty)
          .subscribe(() => this.snackBarService.success("Successfully updated"));
      }

    } else if (
      event.colDef.field === "Title" &&
      event.value !== event.oldValue
    ) {
      const model: EditInventoryItemTitle = {
        stockItemId: event.data.StockItemId,
        title: event.value,
      };

      this.inventoryService.editItemTitle(model).subscribe((result) => {
        this.snackBarService.success("Successfully updated");
      });
    } else if (event.colDef.field === "WooCommerceSalePrice") {
      if (!event.data.WooId) return;
      if (isNaN(event.value) || +event.value < 0) {
        this.snackBarService.warning(
          "Price could not contain non-numeric characters or be lower than 0"
        );
        event.data.WooCommerceSalePrice = event.oldValue;
        this.gridApi.refreshCells();
        return;
      }
      this.inventoryService.updateWooPrice(event.data.WooId, event.value)
      .subscribe(() => this.snackBarService.success("Successfully updated"));
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  //#endregion

  //#region View Manipulation

  public exportToExcel(): void {
    if (!this.userService?.currentUserConfig?.exportToExcelAllowed) {
      return this.snackBarService.warning('You dont have permissions to download Excel sheets');
    }
    const data = this.getRowDataAfterFilter();
    let columns = this.currentView.columns.map(i => ({ header: i.name, key: i.propertyName }));
    columns.unshift({ header: "Title", key: "Title" });
    columns.unshift({ header: "SKU", key: "SKU" });
    this.excelService.exportToExcel(data, columns, this.currentView.title);
  }

  onColumnSelectionChange(event): void {
    // this.currentView.columns = this.currentView.columns.sort();
    const first = event.option.value;
    this.columnDefs.sort(function (x, y) { return x.headerName == first ? -1 : y.headerName == first ? 1 : 0; });
    setTimeout(() => {
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridApi.refreshHeader();
    }, 0);
  }

  manipulateView(event, view: View) {
    // event.stopPropagation();
    this.viewToManipulate = view;
  }

  addNewView() {
    const dialogRef = this.dialog.open(CreateViewDialogComponent, {
      height: "88vh",
      width: "50vw",
      data: {
        columns: this.columns,
        filters: this.filters,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const titleIsAlreadyExists: boolean =
          this.views.filter(
            (x) => x.title.toUpperCase() === result.title.toUpperCase()
          ).length > 0;

        if (titleIsAlreadyExists) {
          let postfix = this.getPostfixToViewTitle(result.title);
          if (postfix != -1) {
            let array = result.title.split("-");
            if (!isNaN(+array[array.length - 1])) {
              array.pop();
            }
            result.title = array.join("-") + `-${++postfix}`;
          }
        }
        this.inventoryService.editOrCreateView(result).subscribe((response) => {
          result.id = response.viewId;

          this.views.push(result);
          if (this.views.length === 1) {
            result.isDefault = true;
            this.currentView = result;
            this.filterTableByView(result);
          }
        });
      }
    });
  }

  editView(event) {
    // event.stopPropagation();
    const previousTitle: string = this.viewToManipulate?.title;
    this.viewToManipulate.columns = this.viewToManipulate?.columns?.filter(
      (x) =>
        !["composite", "sku", "title", "primaryimage"].includes(
          x.propertyName.toLowerCase()
        )
    );
    const dialogRef = this.dialog.open(CreateViewDialogComponent, {
      height: "88vh",
      width: "50vw",
      data: {
        columns: this.columns,
        filters: this.filters,
        view: { ...this.viewToManipulate },
      },
    });
    this.viewToManipulate = null;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const titleIsAlreadyExists: boolean =
          this.views.filter(
            (x) => x.title.toUpperCase() === result.title.toUpperCase()
          ).length > 0;
        if (titleIsAlreadyExists) {
          let postfix = this.getPostfixToViewTitle(result.title);
          if (postfix != -1 && previousTitle !== result.title) {
            let array = result.title.split("-");
            if (!isNaN(+array[array.length - 1])) {
              array.pop();
            }
            result.title = array.join("-") + `-${++postfix}`;
          }
        }
        let viewToUpdate = this.views.filter(
          (x) => x.title === previousTitle
        )[0];
        viewToUpdate.title = result.title;
        viewToUpdate.columns = result.columns;
        viewToUpdate.filters = result.filters;

        this.inventoryService
          .editOrCreateView(viewToUpdate)
          .subscribe((response) => {
            viewToUpdate.id = response.viewId;
            if (previousTitle === this.currentView.title) {
              this.filterTableByView(viewToUpdate);
            }
          });
      }
    });
  }

  deleteView(event) {
    // event.stopPropagation();

    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    const viewToDelete = { ...this.viewToManipulate };
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.inventoryService.deleteView(viewToDelete.id).subscribe((x) => {
          this.viewIdToDelete = viewToDelete.id;
            this.views = this.views.filter((x) => x.id !== viewToDelete.id);
            if (viewToDelete.isDefault && this.views.length > 0) {
              this.views[0].isDefault = true;
              this.inventoryService
                .editOrCreateView(this.views[0])
                .subscribe(() => {
                  this.filterTableByView(this.views[0]);
                });
            }
        });
      }
    });
  }

  removeCompositesFromItemsThatAreCombo(items: any[]): any[] {
    return items;
    items.forEach((item) => {
      if (item.SKU.includes("+") || item.SKU.includes("-")) {
        const seperator: string = item.SKU.includes("+") ? "+" : "-";
        const seperateItemsArray = item.SKU.split(seperator);
        seperateItemsArray.forEach((element) => {
          if (!this.comboItems.includes(element.trim())) {
            this.comboItems.push(element.trim());
          }
        });
      }
    });

    return items.filter((x) => !this.comboItems.includes(x.SKU));
  }

  filterTableByView(view: View) {
    if (this.loading) return;
    this.selectedItem = null;
    // this.lastChangedPrice = null;
    if (this.currentView.title !== view.title) {
      this.sortColumn = undefined;
      this.sortDirection = undefined;
    }
    this.loading = true;
    this.currentView = view;
    // this.gridApi.setRowData([]);
    // this.gridOptions.api.showLoadingOverlay();
    this.pagedList.pageNumber = 0;
    this.pagedList.totalCount = 0;

    this.columnDefs = this.columnDefsBase.filter((x) =>
      ["composite", "sku", "title", "primaryimage"].includes(
        x.field.toLowerCase()
      )
    );
    this.currentView.columns
      .filter((x) => !["composite", "sku", "title", "primaryimage"].includes(x.propertyName.toLowerCase()))
      .forEach((column: Column) => {
        let columnDefItem = {
          ...this.columnDefsBase.filter((x) => x.field === column.propertyName)[0],
        };
        if (column.sortDirection !== SortDirection.None) {
          columnDefItem.headerClass =
            SortDirection[column.sortDirection].toLowerCase();
          this.gridColumnApi.applyColumnState({
            state: [{ colId: column.propertyName, sort: column.sortDirection === SortDirection.Asc ? 'asc' : 'desc' }],
            defaultState: { sort: null },
          });
        } else {
          columnDefItem.headerClass = null;
        }
        this.columnDefs.push(columnDefItem);
        this.columnDefsForColumnFilter = this.columnDefs.filter((x) => x.headerName && x.headerName !== 'Primary Image');

        this.filteredOptions = this.columnFilter.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value)),
        );
      });
    setTimeout(() => {
      this.gridApi.refreshHeader();
    }, 0);

    this.inventoryService
      .getProductScreenItems(
        view.id,
        this.pagedList.pageNumber + 1,
        this.pagedList.itemsPerPage,
        this.sortColumn,
        this.sortDirection,
        this.selectedSupplier?.pkSupplierID ?? null,
        this.selectedCategory?.categoryId ?? null,
        this.selectedLocation?.stockLocationId,
        this.quickFilter,
        true,
        this.showCombos
      )
      .pipe(
        takeUntil(this.destroyedComponentSubj),
        map((data: PagedList) => {
          if (!data) {
            return;
          }
          data.items.forEach((item) => {
            item.isToggled = false;
            item.isChild = false;
            //
            this.currentView.columns
              .filter((x) => !["composite", "sku", "title", "primaryimage"].includes(x.propertyName.toLowerCase()))
              .forEach((column: Column) => {
                let columnDefItem = {
                  ...this.columnDefsBase.filter((x) => x.field === column.propertyName)[0],
                };
                if (column.sortDirection !== SortDirection.None) {
                  columnDefItem.headerClass =
                    SortDirection[column.sortDirection].toLowerCase();
                  this.gridColumnApi.applyColumnState({
                    state: [{ colId: column.propertyName, sort: column.sortDirection === SortDirection.Asc ? 'asc' : 'desc' }],
                    defaultState: { sort: null },
                  });
                } else {
                  columnDefItem.headerClass = null;
                }
              })
            //

          });
          return data;
        })
      )
      .subscribe((result: PagedList) => {
        this.loading = false;
        if (!result) {
          // this.gridApi.showNoRowsOverlay();
          this.pagedList.totalCount = 0;
          return;
        }
        const itemsWithoutCombo: any[] =
          this.removeCompositesFromItemsThatAreCombo(result.items);
        this.pagedList.totalCount =
          result.totalCount - (result.items.length - itemsWithoutCombo.length);
        this.gridApi.setRowData([]);
        this.gridApi.applyTransaction({ add: itemsWithoutCombo });
        if (this.lastSelectedPriceItem) {
          setTimeout(() => {
            this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
              if (rowNode.data.StockItemId === this.lastSelectedPriceItem.StockItemId) {
                // this.gridApi.applyTransaction()
                this.gridApi.ensureIndexVisible(index);
                this.gridApi.getDisplayedRowAtIndex(index).setSelected(true);
              }
            });
          }, 500);
        }

      });
  }

  setDefaultView(view: View) {
    this.views.forEach((view: View) => {
      view.isDefault = false;
    });
    view.isDefault = true;
    this.views = this.views.filter((item: View) => item.id !== view.id);
    this.views.unshift(view);
  }

  setViewAsDefault(event) {
    // event.stopPropagation();
    this.setDefaultView(this.viewToManipulate);
    this.inventoryService.editOrCreateView(this.viewToManipulate).subscribe();
    this.viewToManipulate = null;
  }

  getPostfixToViewTitle(title: string): number {
    function formattedTitle(text: string): string {
      let array = text.split("-");
      if (!isNaN(+array[array.length - 1])) {
        array.pop();
      }
      return array.join("-");
    }

    let viewsWithTheSameTitle = this.views.filter(
      (x) =>
        formattedTitle(x.title.toUpperCase()) ===
        formattedTitle(title.toUpperCase())
    );
    let postfix: number = -1;
    if (viewsWithTheSameTitle.length > 0) {
      const lastTitle = viewsWithTheSameTitle
        .map((x) => x.title)
        .sort()
        .pop();

      postfix = !isNaN(+lastTitle.split("-")[lastTitle.split("-").length - 1])
        ? +lastTitle.split("-")[lastTitle.split("-").length - 1]
        : 0;
    }
    return postfix;
  }

  loadInitialDataForDefaultView() {
    const defaultView = this.views.filter((view: View) => view.isDefault)[0];
    this.currentView = defaultView;
    if (defaultView) {
      this.filterTableByView(defaultView);
    } else {
      if (this.views.length > 0) {
        this.views[0].isDefault = true;
        this.currentView = this.views[0];
        this.inventoryService.editOrCreateView(this.views[0]).subscribe(() => {
          this.filterTableByView(this.views[0]);
        });
      } else {
        this.gridApi.showNoRowsOverlay();
      }
    }
  }

  //#endregion

  //#region Dropdown Selection, Input Search

  showCombosToggleChange(params): void {
    this.filterTableByView(this.currentView);
  }

  selectedSupplierChange(value): void {
    setTimeout(() => {
      this.selectedItem = null;
      this.gridApi.setRowData([]);
      this.selectedSupplier = value;
      this.pagedList.totalCount = 0;
      this.loadProductItems(
        this.currentView,
        1,
        this.pagedList.itemsPerPage,
        null,
        null,
        this.selectedSupplier?.pkSupplierID,
        this.selectedCategory?.categoryId,
        this.selectedLocation?.stockLocationId,
        this.quickFilter,
        true,
        this.showCombos
      );

      this.pagedList.pageNumber = 0;
    }, 0);
  }

  selectedCategoryChange(value): void {
    setTimeout(() => {
      this.selectedItem = null;
      this.gridApi.setRowData([]);
      this.selectedCategory = value;
      this.pagedList.totalCount = 0;
      this.loadProductItems(
        this.currentView,
        1,
        this.pagedList.itemsPerPage,
        null,
        null,
        this.selectedSupplier?.pkSupplierID,
        this.selectedCategory.categoryId,
        this.selectedLocation?.stockLocationId,
        this.quickFilter,
        true,
        true
      );
      this.pagedList.pageNumber = 0;
    }, 0);
  }

  selectedLocationChange(value): void {
    setTimeout(() => {
      this.selectedItem = null;
      this.gridApi.setRowData([]);
      this.selectedLocation = value;
      this.pagedList.totalCount = 0;
      this.loadProductItems(
        this.currentView,
        1,
        this.pagedList.itemsPerPage,
        null,
        null,
        this.selectedSupplier?.pkSupplierID,
        this.selectedCategory?.categoryId,
        this.selectedLocation?.stockLocationId,
        this.quickFilter,
        true,
        this.showCombos
      );
      this.pagedList.pageNumber = 0;
    }, 0);
  }

  // onQuickFilterChange(text: string) {
  //   this.modelChanged.next(text);
  // }

  onQuickFilterChange() {
    this.modelChanged.next(this.quickFilter);
  }

  //#endregion

  //#region Third-Party Buttons
  createNewItem(): void {
    this.openDialog(AddNewItemComponent, {}, 400);
  }

  deleteItem() {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      `Are you sure to delete ${this.selectedItem.SKU}?`
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.inventoryService
          .DeleteInventoryItem(this.selectedItem.StockItemId)
          .subscribe(() => {
            this.snackBarService.success(
              `${this.selectedItem.SKU} was deleted`
            );
          });
      }
    });
  }

  openInventoryImagesDialogComponent(): void {
    this.openDialog(InventoryImagesDialogComponent, null);
  }

  openSpecSheetDialogComponent() {
    this.openDialog(SpecSheetDialogComponent, null);
  }

  openInstructionsDialogComponent() {
    this.openDialog(InstructionsDialogComponent, null);
  }

  openPriceChanger(): void {
    this.openDialog(PriceChangerComponent, '', 1200, 560);
  }

  openNewPriceRuleDialog(): void {
    this.openDialog(PriceChangeRulesComponent, '', 1000, 560);
  }

  openPriceRules(): void {
    this.openDialog(AllPriceRulesComponent, '', 1200, 560);
  }

  copyPriceToAllChannels(): void {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
      // this.lastChangedPrice = null;
    }
    if (!this.lastChangedPrice) {
      this.snackBarService.warning("There is no item with changed price");
      return;
    }
    if (this.lastChangedPrice?.stockItemId !== this.selectedItem.StockItemId) {
      return;
    };

    const dialogData = new ConfirmDialogModel(
      "Confirm Price Change",
      "All channel prices will be changed to " + this.lastChangedPrice.price + " GBP"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.inventoryService.copyPriceToAllChannels(this.lastChangedPrice.stockItemId, this.lastChangedPrice.price).subscribe(result => {
          this.snackBarService.success("Prices were successfully updated");
          this.lastSelectedPriceItem = this.gridApi.getSelectedRows()[0];
          this.inventoryService
            .getProductScreenItems(
              this.currentView.id,
              this.pagedList.pageNumber + 1,
              this.pagedList.itemsPerPage,
              this.sortColumn,
              this.sortDirection,
              this.selectedSupplier?.pkSupplierID ?? null,
              this.selectedCategory?.categoryId ?? null,
              this.selectedLocation?.stockLocationId,
              this.quickFilter,
              true,
              this.showCombos
            )
            .pipe(
              takeUntil(this.destroyedComponentSubj),
              map((data: PagedList) => {
                if (!data) {
                  return;
                }
                return data;
              })
            )
            .subscribe((result: PagedList) => {
              this.loading = false;
              if (!result) {
                this.pagedList.totalCount = 0;
                return;
              }
              let itemsToUpdate = [];
              setTimeout(() => {
                this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
                  const updatedItem = result.items.find(i => i.StockItemId === rowNode.data.StockItemId);
                  let data = rowNode.data;
                  data.AmazonChannelPrice = updatedItem.AmazonChannelPrice;
                  data.AmazonFBAChannelPrice = updatedItem.AmazonFBAChannelPrice;
                  data.EbayChannelPrice = updatedItem.EbayChannelPrice;
                  data.OnBuyChannelPrice = updatedItem.OnBuyChannelPrice;
                  data.HometreatsChannelPrice = updatedItem.HometreatsChannelPrice;
                  data.MiraklMPChannelPrice = updatedItem.MiraklMPChannelPrice;
                  data.RangeChannelPrice = updatedItem.RangeChannelPrice;
                  data.DebenhamsChannelPrice = updatedItem.DebenhamsChannelPrice;
                  data.TescoChannelPrice = updatedItem.TescoChannelPrice;
                  itemsToUpdate.push(data);
                });
                this.gridApi.applyTransaction({ update: itemsToUpdate });
              }, 500);
            });
        });
      }
    });
  }

  priceUp(): void {
    if (this.priceUpAmount === 0) {
      this.snackBarService.warning("Please set the amount to change");
      return;
    }

    const dialogData = new ConfirmDialogModel(
      "Confirm Price Change",
      "All channel prices will be changed by " + this.priceUpAmount + " GBP"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {

        const itemsToUpdate = this.gridApi.getSelectedRows().map(x => x.StockItemId);
        this.inventoryService.updatePriceByAmount(itemsToUpdate, this.priceUpAmount).subscribe(result => {
          this.snackBarService.success("Prices were successfully updated");
          this.lastSelectedPriceItem = this.gridApi.getSelectedRows()[0];
          // this.filterTableByView(this.currentView);
          //
          this.inventoryService
            .getProductScreenItems(
              this.currentView.id,
              this.pagedList.pageNumber + 1,
              this.pagedList.itemsPerPage,
              this.sortColumn,
              this.sortDirection,
              this.selectedSupplier?.pkSupplierID ?? null,
              this.selectedCategory?.categoryId ?? null,
              this.selectedLocation?.stockLocationId,
              this.quickFilter,
              true,
              this.showCombos
            )
            .pipe(
              takeUntil(this.destroyedComponentSubj),
              map((data: PagedList) => {
                if (!data) {
                  return;
                }
                return data;
              })
            )
            .subscribe((result: PagedList) => {
              this.loading = false;
              if (!result) {
                this.pagedList.totalCount = 0;
                return;
              }
              let itemsToUpdate = [];
              setTimeout(() => {
                this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
                  const updatedItem = result.items.find(i => i.StockItemId === rowNode.data.StockItemId);
                  let data = rowNode.data;
                  data.AmazonChannelPrice = updatedItem.AmazonChannelPrice;
                  data.AmazonFBAChannelPrice = updatedItem.AmazonFBAChannelPrice;
                  data.EbayChannelPrice = updatedItem.EbayChannelPrice;
                  data.OnBuyChannelPrice = updatedItem.OnBuyChannelPrice;
                  data.HometreatsChannelPrice = updatedItem.HometreatsChannelPrice;
                  data.MiraklMPChannelPrice = updatedItem.MiraklMPChannelPrice;
                  data.RangeChannelPrice = updatedItem.RangeChannelPrice;
                  data.DebenhamsChannelPrice = updatedItem.DebenhamsChannelPrice;
                  data.TescoChannelPrice = updatedItem.TescoChannelPrice;
                  itemsToUpdate.push(data);
                });
                this.gridApi.applyTransaction({ update: itemsToUpdate });
              }, 500);
            });
          //
        });
      }
    });
  }

  openDialog<T>(
    component: ComponentType<T>,
    data: any,
    minWidth?: number,
    minHeight?: number
  ) {
    return this.dialog.open(component, {
      data: data ?? {
        stockItemId: this.selectedItem.StockItemId,
        SKU: this.selectedItem.SKU,
      },
      maxHeight: "600px",
      maxWidth: "1200px",
      minHeight: minHeight ? `${minHeight}px` : "0",
      minWidth: minWidth ? `${minWidth}px` : "0",
    });
  }

  duplicateInventoryItem() {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.inventoryService
          .duplicateInventoryItem(this.selectedItem.StockItemId)
          .subscribe((response) => {
            this.snackBarService.success(
              `Inventory item was duplicated with sku ${response.itemNumber}`
            );
          });
      }
    });
  }

  setItemAsDiscontinued() {
    this.inventoryService
      .setItemAsDiscontinued(this.selectedItem.StockItemId)
      .subscribe(() => {
        this.snackBarService.success(
          `Inventory item ${this.selectedItem.SKU} was marked as discontinued `
        );
        const discontinuedViews: View[] = this.views.filter((x) =>
          x.title.toLowerCase().trim().includes("discontinued")
        );
        if (discontinuedViews.length === 0) {
          this.snackBarService.warning("There is no discontinued view");
          return;
        }
        this.filterTableByView(discontinuedViews[0]);
      });
  }

  selectedFiles: FileList;
  uploadFiles(event) {
    this.selectedFiles = event.target.files;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      let category: string = "images";
      this.uploadFile(this.selectedFiles[i], category);
    }
  }

  uploadFile(file, category: string) {
    let limitSize: number = 52428800;

    if (file.size > limitSize) {
      this.snackBarService.warning(`File size cannot be more than 50 MB`);
      return;
    }

    const formData = new FormData();

    let itemTitle = this.selectedItem.Title.replace(/\s/g, "_");
    let timeStamp = moment().format("YYMMDDHHmmssSSS");
    let fileExtension = file.name.substring(file.name.indexOf("."));
    let fileKey = `${this.selectedItem.StockItemId}/${this.selectedItem.SKU}_${itemTitle}_${timeStamp}${fileExtension}`;

    formData.append(fileKey, file);
  }

  @ViewChild("inputFile") inputFile: ElementRef;

  resetStockValue() {
    this.inventoryService
      .ResetStockValue(this.selectedItem.StockItemId)
      .subscribe((response) => {
        const rowNode = this.gridApi.getSelectedNodes()[0];
        rowNode.data.StockValue = response.value;
        rowNode.data.StockValueIsOverridden = false;
        this.gridApi.refreshCells();
        this.snackBarService.success("Stock value was successfully restored");
      });
  }

  configureSupplierState() {
    // const dialogRef = this.openDialog(
    //   SuppliersStateConfigurationComponent,
    //   this.suppliersBase
    // );
    let dialogRef = this.dialog.open(SuppliersStateConfigurationComponent, {
      width: "720px"
    });
    dialogRef.componentInstance.suppliers = this.suppliersBase;
    dialogRef.componentInstance.ports = this.ports;
    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.getSuppliers();
    });
  }
  // #endregion

  //#region XLSX Exprort
  getRowDataAfterFilter() {
    let rows = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
      var data = rowNode.data;
      rows.push(data);
    });
    return rows;
  }
  exportExcel(): void {
    const dataFromCurrentView = this.getRowDataAfterFilter();
    // const displayedColumns = this.columnDefs.map(x => x.field)
    //   .filter(
    //     x => x.toLowerCase() !== 'inactive'
    //    && x.toLowerCase() !== 'discontinued'
    //    && x.toLowerCase() !== 'primaryimage'
    //    );

    const displayedColumns = this.columnDefs.map(x => x.field)
      .filter(
        x => x.toLowerCase() !== 'inactive'
       && x.toLowerCase() !== 'discontinued'
       && x.toLowerCase() !== 'primaryimage'
       );
    const dataToExport = dataFromCurrentView.map(this.selectProps(displayedColumns));
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, {
      skipHeader: false,
      dateNF: "dd/MM/yy",
      cellDates: true,
    });


    for (let col = 0; col < this.gridColumnApi.getAllDisplayedColumns().length; col++) {
      this.formatColumn(worksheet, col, displayedColumns);
    }
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "ProductDetails");
  }

  formatColumn(worksheet: any, col: any, columns: any[], fmt?: any): void {
    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col });

      const coldef = columns[col];
      if (worksheet[ref] && (this.inventoryService.numberColumnsProductScreen.includes(coldef) || coldef.toLowerCase().includes('sales'))) {
        worksheet[ref].t = 'n';
      }
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + this.currentView.title + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  pascalCaseToSentence(input: string): string {
    return input.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return " " + y }).replace(/^_/, "");
  }
  selectProps(...props) {
    return function (obj) {
      const newObj = {};
      props[0].forEach(name => {
        newObj[name] = obj[name];
      });
      return newObj;
    }
  }

  onQuickFilterChanged() {
    this.gridOptions.api.setQuickFilter(this.searchText);
    this.gridOptions.api.onFilterChanged();
  }
}

function digitFormatter(params) {
  if (isNaN(params.value)) {
    return;
  }
  return params?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
